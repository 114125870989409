import React, { useState } from "react";
import data from "./catalog.json";

function ProductList({
  filteredProducts,
  onProductSelect,
  selectedSubCategory,
}) {
  let productsToDisplay = filteredProducts;
  if (
    selectedSubCategory &&
    selectedSubCategory.subcategories &&
    selectedSubCategory.subcategories.length > 0
  ) {
    productsToDisplay = selectedSubCategory.products;
  }

  return (
    <>
      <div className="risoFarbfinder--slider">
        {productsToDisplay.map((product) => (
          <article className="risoFarbfinder--card" style={{backgroundImage: `url(/farbfinder/images/${product.backgroundImage})`}} key={product.id}>
            <figure className="risoFarbfinder--card-figure">
              <img
                onClick={() => onProductSelect(product)}
                src={window.location.origin + "/farbfinder/images/" + product.image}
                alt={product.name}
              />
            </figure>
            <header className="risoFarbfinder--header">
              <h2 onClick={() => onProductSelect(product)}>{product.name}</h2>
            </header>
          </article>
        ))}
      </div>
    </>
  );
}

function ProductDetail({ product, onBack }) {
  const [previousState, setPreviousState] = useState(null);

  const handleProductClick = () => {
    setPreviousState(product);
  };

  return (
    <>
      <button className="back" onClick={onBack}>
        Zurück
      </button>

      <article className="risoFarbfinder--single-product">
        <header>
          <h2 onClick={handleProductClick}>
            {product.name} | {"#" + product.id}
          </h2>
          <figure className="risoFarbfinder--single-product-gallery">
            {product.gallery.map((image) => (
              <img
                key={image}
                src={window.location.origin + "/farbfinder/images/" + image}
                alt={product.name}
              />
            ))}
          </figure>
        </header>

        <div className="risoFarbfinder--single-product-details">
          <p>{product.description}</p>
          <dl>
            <dt className="usage">Nadelempfehlungen:</dt>
            <dd>{product.warning}</dd>
            <br />
            <dt className="mixture">Ideale Farbmischung:</dt>
            <dd>{product.mixture}</dd>
            <br />
            <dt className="warning">Das gilt es zu beachten:</dt>
            <dd>{product.usage}</dd>
          </dl>
        </div>

        <a
          className="btn"
          target="_blank"
          href={product.shopLink}
          titel="jetzt kaufen"
        >
          Jetzt bestellen
        </a>
      </article>
    </>
  );
}

function Navigation({
  categories,
  selectedCategory,
  onCategoryChange,
  subCategories,
  selectedSubCategory,
  onSubCategoryChange,
}) {
  return (
    <>
      {!selectedCategory && (
        <>
          <div className="risoFarbfinder--slider is-style-startscreen">
            {categories.map((categoryName) => (
              <article
                className="risoFarbfinder--card"
                key={categoryName}
                onClick={() =>
                  onCategoryChange({ target: { value: categoryName } })
                }
              >
                <figure className="risoFarbfinder--card-figure">
                  <img
                    className="risoFarbfinder--category-image"
                    src={window.location.origin + "/farbfinder/images/" +
                      data.catalog.find(
                        (category) => category.name === categoryName
                      ).image
                    }
                    alt={categoryName}
                  />
                </figure>
                <header className="risoFarbfinder--header">
                  <h2>{categoryName}</h2>
                </header>
              </article>
            ))}
          </div>
        </>
      )}

      {selectedCategory && !selectedSubCategory && (
        <>
          <div className="risoFarbfinder--slider">
            {subCategories.map((subcategory) => (
              <label
                className="risoFarbfinder--card"
                data-name={subcategory}
                key={subcategory}
              >
                <input
                  type="radio"
                  name="subCategory"
                  value={subcategory}
                  checked={selectedSubCategory === subcategory}
                  onChange={onSubCategoryChange}
                />
                <figure className="risoFarbfinder--card-figure">
                  <img
                    src={
                      window.location.origin +
                      "/farbfinder/images/" +
                      data.catalog
                        .find((category) => category.name === selectedCategory)
                        ?.subcategories.find(
                          (subcategoryObj) =>
                            subcategoryObj.name === subcategory
                        )?.image
                    }
                    alt={subcategory}
                  />
                </figure>
                <header className="risoFarbfinder--header">
                  <h2>{subcategory}</h2>
                </header>
              </label>
            ))}
          </div>
        </>
      )}

      <nav className="risoFarbfinder--navigation-fixed">
        {categories.map((categoryName) => (
          <label data-name={categoryName} key={categoryName}>
            <input
              type="radio"
              name="category"
              value={categoryName}
              checked={selectedCategory === categoryName}
              onChange={onCategoryChange}
            />
            <span
              className={"risoFarbfinder--navigation-icon " + categoryName}
            ></span>
            <span className="risoFarbfinder--navigation-label">
              {categoryName}
            </span>
          </label>
        ))}
      </nav>
    </>
  );
}

function App() {
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const categories = data.catalog.map((category) => category.name);

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory);
    setSubCategory("");
    setSelectedProduct(null);
    const subCategories =
      data.catalog
        .find((category) => category.name === selectedCategory)
        ?.subcategories.map((subcategory) => subcategory.name) ?? [];
    setSubCategories(subCategories);
  };

  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
    setSelectedProduct(null);
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };

  const handleBack = () => {
    if (selectedProduct) {
      setSelectedProduct(null);
    } else if (subCategory) {
      setSubCategory("");
    }
  };

  let filteredProducts =
    data.catalog
      .find((categoryData) => categoryData.name === category)
      ?.products.filter((product) =>
        subCategory
          ? product.subcategories && product.subcategories.includes(subCategory)
          : !product.subcategories || product.subcategories.length === 0
      ) ?? [];

  if (subCategories.length === 0) {
    filteredProducts =
      data.catalog.find((categoryData) => categoryData.name === category)
        ?.products ?? [];
  }

  return (
    <div className="ifdigital-react-pwa">
      <div id="logo"></div>
      <div className="risoFarbfinder">
        {category &&
        (subCategory ||
          subCategories.length === 0 ||
          filteredProducts.length === 0) ? (
          selectedProduct ? (
            <ProductDetail product={selectedProduct} onBack={handleBack} />
          ) : (
            <>
              {subCategory && (
                <button className="back" onClick={handleBack}>
                  Zurück
                </button>
              )}
              <ProductList
                filteredProducts={filteredProducts}
                onProductSelect={handleProductSelect}
              />
            </>
          )
        ) : (
          <></>
        )}

        <Navigation
          categories={categories}
          selectedCategory={category}
          onCategoryChange={handleCategoryChange}
          subCategories={subCategories}
          selectedSubCategory={subCategory}
          onSubCategoryChange={handleSubCategoryChange}
        />
      </div>
    </div>
  );
}

export default App;
